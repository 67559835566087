import { useState, useEffect, useRef } from 'react'
import axios from 'axios'


export default function useUploadFiles(endPoint, filesToUpload, setLocalFiles, fileInfosList, maxFilesCount, fileUploadSizeLimitInMB, maxVideosCount = 1) {
    const [error, setError] = useState('')

    const initVal = fileInfosList ? fileInfosList : []

    const [fileInfos, setFileInfos] = useState(initVal)
    const fileInfosRef = useRef(null)
    // fileInfosRef.current = initVal

    const fileUploadSizeLimitInBytes = fileUploadSizeLimitInMB * 1000000

    // handle Unmount component
    useEffect(() => {
        return () => {
            // cancel all active upload requests
            fileInfosRef.current?.map(f => f.cancelSource?.cancel ? f.cancelSource.cancel() : null)
        }
    }, [])


    const createFileInfo = (file) => {
        return {
            name: file.name,
            error: file.size > fileUploadSizeLimitInBytes ? `You can not upload file larger than ${fileUploadSizeLimitInMB} MB.` : null,
            percentage: 0,
            cancelSource: file.size < fileUploadSizeLimitInBytes ? axios.CancelToken.source() : null,
            content_type: file.type || file.content_type,
            uploading: false,
            uploaded: false,
            id: null,
            file: null,
            image: null,
            localFile: file
        }
    }


    const upload = async(file) => {
        const formData = new FormData()
        formData.append('file', file)

        let fileInfo = fileInfosRef.current.filter(f => f.name === file.name)[0]

        if (fileInfo) {
            try {
                const response = await axios.post(endPoint, formData,
                    {
                        cancelToken: fileInfo.cancelSource.token,
                        headers: { "Content-Type": "multipart/form-data" },
                        onUploadProgress: (event) => {
                            fileInfo.percentage = Math.round((100 * event.loaded) / event.total)
                            fileInfo.uploading = true

                            fileInfosRef.current = fileInfosRef.current.map(f => f.name === file.name ? fileInfo : f)
                            setFileInfos(fileInfosRef.current)
                        }
                    })

                if (response?.status === 201) {
                    fileInfo.file = response.data?.file
                    fileInfo.image = response.data?.image
                    fileInfo.id = response.data.id
                    fileInfo.uploading = false
                    fileInfo.uploaded = true

                    fileInfosRef.current = fileInfosRef.current.map(f => f.name === file.name ? fileInfo : f)
                    setFileInfos(fileInfosRef.current)
                }

            } catch (err) {
                if (axios.isCancel(err)) return
                fileInfo.percentage = 0
                fileInfo.uploading = false
                fileInfo.error = 'Could not upload the file: ' + file.name

                fileInfosRef.current = fileInfosRef.current.map(f => f.name === file.name ? fileInfo : f)
                setFileInfos(fileInfosRef.current)
            }
        }
    }


    // handle upload files
    useEffect(() => {

        if (filesToUpload.length > 0) {
            let files
            setError('')

            //check if filesToUpload has videos more than maxVideosCount
            if (filesToUpload.length > 1) {
                let videoCounts = 0
                var fileTpyesArr = filesToUpload.map(f => f.type)
                fileTpyesArr.forEach(item => {
                    if (item.includes('video')) videoCounts++
                })
                if (videoCounts > maxVideosCount) {
                    setError(`You can not upload more than ${maxVideosCount} video.`)
                    setLocalFiles([])
                    return
                }
            }

            if (fileInfos.length > 0) {

                const localFilesFilteredList = filesToUpload.filter(file => {
                    const filesVideoCount = fileInfos.filter(f => f.content_type?.includes('video')).length

                    // check if fileInfos have max videos
                    if (file.type?.includes('video') && filesVideoCount >= maxVideosCount) {
                        setError(`You can not upload more than ${maxVideosCount} video.`)
                        return false

                    } else {
                        return !fileInfos.some(f => f.name === file.name)
                    }
                })

                const notUploadedFileInfos = localFilesFilteredList.map(file => {
                    return createFileInfo(file)
                })

                files = [...fileInfos, ...notUploadedFileInfos]

            } else {
                files = filesToUpload.map(file => (createFileInfo(file)))
            }

            // check if files count higher than maxFilesCount
            if (files.length > maxFilesCount) {
                setError(`You can not upload more than ${maxFilesCount} files.`)
                setLocalFiles([])
                return
            } else {
                fileInfosRef.current = files
                setFileInfos(files)
            }

            files.forEach((file) => {
                if (file.localFile && !file.uploaded && !file.uploading && (file.localFile.size <= fileUploadSizeLimitInBytes)) {
                    upload(file.localFile)
                }
            })

            setLocalFiles([])
        }
    }, [filesToUpload])



    // handle delete file
    const handleDeleteFile = async(fileInfo, index) => {
        try {
            setError('')
            // handle delete uploaded file
            if (fileInfo.id) {
                await axios.delete(`${endPoint}${fileInfo.id}/`)
            } else {
                fileInfo.cancelSource?.cancel()
            }
            // fileInfosRef.current = fileInfos.filter(f => f.name !== fileInfo.name)
            // setFileInfos(fileInfosRef.current)

        } catch (err) {
            // fall silently
        }
        fileInfosRef.current = fileInfos.filter(f => f.name !== fileInfo.name)
        setFileInfos(fileInfosRef.current)
    }


    // uploadFiles
    const uploadFiles = fileInfos.map((fileInfo, index) => (
        {
            ...fileInfo,
            fileSrc: (fileInfo.file || fileInfo.image) ?
                (fileInfo.file || fileInfo.image) :
                fileInfo.fileSrc ? fileInfo.fileSrc : URL.createObjectURL(fileInfo.localFile),
            deleteFile: () => handleDeleteFile(fileInfo, index),
        }
    ))

    return { uploadFiles, error, setFileInfos }

}