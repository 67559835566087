

export default function PreviewUrl({ urlPreview, setUrlPreviews, editMode, index, compactView }) {

    return (
        <div style={{minWidth: 0}} className={urlPreview.site_video_url && !compactView ? 'm-0 mt-2' : "position-relative background-color-light card w-100 shadow-xss pointer rounded-xxl border-0 mt-3"}>
            {/* close */}
            {editMode && <span onClick={() => setUrlPreviews(prev => prev.filter((p, idx) => idx !== index))} className="position-absolute pointer m-2 right-0 btn-round-sm background-color-lightest text-color"> &#x2715; </span>}

            {compactView && <a className="row g-0" href={urlPreview.url} target="_blank" rel="noreferrer">
                {urlPreview.image && <div className="col-4">
                    <img src={urlPreview.image} style={{ borderRadius: '15px  0 0 15px', width: '100%', height: '100%', maxHeight: 110, objectFit: 'cover' }} className="bg-white" alt={urlPreview.title} ></img>
                </div>}
                <div className={urlPreview.image ? "col-8" : "col-12"} style={{minWidth: 0}}>
                    <div className="card-body">
                        <h6 className="fw-500 text-color-light font-xss text-truncate">{urlPreview.title}</h6>
                        <p className="fw-500 text-color-lighter font-xssss m-0 text-truncate"> {urlPreview.description}</p>
                        <span className="fw-500 text-primary font-xsss">{urlPreview.domain}</span>
                    </div>
                </div>
            </a>}

            {!compactView && <>
                {urlPreview.site_video_url ?
                    // video
                    <iframe src={urlPreview.site_video_url} allow="fullscreen;" style={{ width: '100%', height: '370px', objectFit: 'cover' }} title={urlPreview.title}></iframe> :

                    // image 
                    <a href={urlPreview.url} target="_blank" rel="noreferrer">

                        {urlPreview.image &&
                            <div>
                                <img src={urlPreview.image} style={{ borderRadius: '15px 15px 0 0', width: '100%', height: '100%', maxHeight: '270px', objectFit: 'cover' }} alt={urlPreview.title} className="bg-white" />
                            </div>
                        }

                        <div className="card-body p-3">
                            <h4 className="fw-500 text-color-light font-xss mt-1"> {urlPreview.title}</h4>
                            <p className="fw-500 text-color-lighter font-xssss w-100 mb-1">{urlPreview.description}</p>
                            <span className="fw-500 text-primary font-xsss">{urlPreview.domain}</span>
                        </div>

                    </a>}
            </>}

        </div>

    )

}
