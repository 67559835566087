import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import Clamp from 'react-multiline-clamp'
import { Editor, EditorState, RichUtils, AtomicBlockUtils, getDefaultKeyBinding, Modifier, CompositeDecorator, convertFromRaw, convertToRaw, ContentState, SelectionState } from 'draft-js'
// import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import 'draft-js/dist/Draft.css'
import styles from './ContentEditor.module.css'


import { FaImage, FaVideo, FaPaperclip } from 'react-icons/fa'

import { BlockStyleControls, InlineStyleControls, getCaretPopupPosition, getCaretLocation, getText, getCurrentBlock, getCurrentCleanContent } from './utils'
import MentionComponent from './MentionComponent'
import UserSuggestion from './UserSuggestion'

import EmojiPickerComponent from "./EmojiPickerComponent"
import EmojiComponent from "./EmojiComponent"
import 'emoji-mart/css/emoji-mart.css'
import HashtagComponent from "./HashtagComponent"
import UrlComponent from "./UrlComponent"

import useUploadFiles from '../hooks/useUploadFiles'
import useCloseWhenClickOut from '../hooks/useCloseWhenClickOut'
// import useFetchUrlPreview from '../hooks/useFetchUrlPreview'

import ConditionalWrapper from "../components/ConditionalWrapper"
import UploadFile from "../components/UploadFile"
import PreviewUrl from "../components/PreviewUrl"


import { regex } from '../utils/utils'
import VideoPlayer from '../components/VideoPlayer'


const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText()
    let matchArr, start
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index
        callback(start, start + matchArr[0].length)
    }
}


const hashtagStrategy = (contentBlock, callback, contentState) => {
    findWithRegex(regex.hashtagRegex, contentBlock, callback)
}


const emojiStrategy = (contentBlock, callback, contentState) => {
    findWithRegex(regex.colonsEmojiRegex, contentBlock, callback)
}


const urlStrategy = (contentBlock, callback, contentState) => {
    findWithRegex(regex.urlRegex, contentBlock, callback)
}

const newEntityLocationStrategy = type => {
    const findEntitiesOfType = (contentBlock, callback, contentState) => {
        contentBlock.findEntityRanges(character => {
            const entityKey = character.getEntity()
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === type
            )
        }, callback)
    }
    return findEntitiesOfType
}




const decorator = (enableEmoji, enableHashtag, enableMention, enableLinkify, readOnly, enableOpenUrl, enableUrlPreview, urlPreviewsRef, setUrlPreviews) => {
    const compositeDecoratorArr = []

    if (enableEmoji) {
        compositeDecoratorArr.push({ strategy: emojiStrategy, component: EmojiComponent })
    }

    if (enableHashtag) {
        compositeDecoratorArr.push({ strategy: hashtagStrategy, component: HashtagComponent, props: { readOnly } })
    }

    if (enableMention) {
        compositeDecoratorArr.push({ strategy: newEntityLocationStrategy('MENTION'), component: MentionComponent, props: { readOnly } })
    }

    if (enableLinkify) {
        compositeDecoratorArr.push({ strategy: urlStrategy, component: UrlComponent, props: { readOnly, enableOpenUrl, enableUrlPreview, setUrlPreviews, urlPreviewsRef } })
    }
    return new CompositeDecorator(compositeDecoratorArr)
}











export default function ContentEditor(props) {
    const { storageName,
        readOnly,
        mentionsOnly,
        placeHolder,
        enableTextFormat,
        stripPastedStyles,
        enableEmoji,
        enableMention,
        enableUrlPreview,
        enableLinkify = true,
        enableOpenUrl = true,
        enableUploadFiles,
        uploadFilesEndpoint,
        removeMentionPrefix,
        enableHashtag,
        formattedContent,
        urlPreviewsList,
        uploadedFileList,
        action,
        submitButtonName,
        handleSubmit,
        enableEnterKeyToSubmit,
        setContent,
        clampOptions,
        insidePopup = false
    } = props


    const [isLoading, setIsLoading] = useState(false)



    const storageContent = window.localStorage.getItem(storageName)

    const passedInOrStorageUrlPreviews = urlPreviewsList ? urlPreviewsList : storageContent ? JSON.parse(storageContent)?.urlPreviews : []
    const urlPreviewsRef = useRef(null)
    urlPreviewsRef.current = passedInOrStorageUrlPreviews
    const [urlPreviews, setUrlPreviews] = useState(passedInOrStorageUrlPreviews)
    // const [urlPreviews, setUrlPreviews] = useState({previews: urlPreviewsList ? urlPreviewsList : storageContent ? JSON.parse(storageContent)?.urlPreviews : []})

    const compositeDecorator = decorator(enableEmoji, enableHashtag, enableMention, enableLinkify, readOnly, enableOpenUrl, enableUrlPreview, urlPreviewsRef, setUrlPreviews)

    const [editorState, setEditorState] = useState(formattedContent ? () => EditorState.createWithContent(convertFromRaw(formattedContent), compositeDecorator) : (storageContent ? () => EditorState.createWithContent(convertFromRaw(JSON.parse(storageContent)?.formattedContent), compositeDecorator) : () => EditorState.createEmpty(compositeDecorator)))
    const [mention, setMention] = useState(undefined)



    const [localFiles, setLocalFiles] = useState([])
    // const { isLoading: UrlPreviewLoading, urlPreview, setUrlPreview } = useFetchUrlPreview(data.content)
    // const { isLoading: UrlPreviewLoading, urlPreviews,  setUrlPreviews} = useFetchUrlPreview(data.content)
    const passedInORStorageUploadedFiles = uploadedFileList ? uploadedFileList.map(f => ({ ...f, error: null, percentage: 100, type: f.type ? f.type : f.content_type, uploading: false, uploaded: true, cancelSource: null })) : storageContent ? JSON.parse(storageContent)?.uploadFiles : []
    const { uploadFiles, setFileInfos, error: fileError } = useUploadFiles(uploadFilesEndpoint, localFiles, setLocalFiles, passedInORStorageUploadedFiles, process.env.REACT_APP_CHAT_MAX_FILES_COUNT, process.env.REACT_APP_CHAT_FILE_SIZE_LIMIT)

    const inputId = Math.random()
    const editorWrapperRef = useRef(null)
    const editorRef = useRef(null)
    const UserSuggestionRef = useRef(null)
    const fileInputRef = useRef(null)

    // handle closing when click outside 
    useCloseWhenClickOut(UserSuggestionRef, () => setMention(undefined))

    const user = useSelector((state) => state.userReducer.user)


    // handle clearing input files
    useEffect(() => {
        if (!readOnly && enableUploadFiles && uploadFilesEndpoint) {
            fileInputRef.current.value = null
        }
    }, [localFiles])


    //handle file errors
    useEffect(() => {
        if (fileError) {
            toast.error(fileError)
        }
    }, [fileError])



    // save draft data to localStorage if it's enabled
    useEffect(() => {
        // save the draft to localStorage
        if (storageName) {
            const rawEditorState = convertToRaw(editorState.getCurrentContent())
            const draft = JSON.stringify({ formattedContent: rawEditorState, uploadFiles, urlPreviews })
            window.localStorage.setItem(storageName, draft)
        }

    }, [editorState, uploadFiles, urlPreviews])


    // set content if it's enabled
    useEffect(() => {
        if (!readOnly && setContent) {
            const rawEditorState = convertToRaw(editorState.getCurrentContent())
            const mentions = Object.values(rawEditorState.entityMap).filter(entity => entity.type === 'MENTION').map(entity => entity.data.id)
            const contentWithEmojis = editorState.getCurrentContent().getPlainText().trim()
            const content = contentWithEmojis.replace(regex.colonsEmojiRegex, '')
            setContent({ content, contentWithEmojis, formattedContent: rawEditorState, mentions })
        }
    }, [editorState])



    // Cmd+B (bold), Cmd+I (italic)
    const handleKeyCommand = (command, editorState) => {

        if (!enableTextFormat && command !== 'split-block') return 'not-handled'

        const newState = RichUtils.handleKeyCommand(editorState, command)

        if (newState) {
            setEditorState(newState)
            return 'handled'
        }
        return 'not-handled'
    }





    const toggleInlineStyle = (inlineStyle) => {
        setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
    }

    const toggleBlockType = (blockType) => {
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    }



    const handleAddingEmoji = (emoji) => {

        const selection = editorState.getSelection()
        const contentState = editorState.getCurrentContent()

        const contentStateWithEmojiEntity = contentState.createEntity('EMOJI', 'IMMUTABLE', { native: emoji.native, colons: emoji.colons })
        const entityKey = contentStateWithEmojiEntity.getLastCreatedEntityKey()

        // in case text is selected it is removed and then the emoji is added
        const afterRemovalContentState = Modifier.removeRange(contentState, selection, 'backward')
        // deciding on the position to insert emoji
        const targetSelection = afterRemovalContentState.getSelectionAfter()

        const newContentStateWithAddedColon = Modifier.replaceText(afterRemovalContentState, targetSelection, emoji.colons, null, entityKey)
        // const newContentStateWithAddedColon = Modifier.replaceText(afterRemovalContentState, targetSelection, emoji.native, null, entityKey)


        const newEditorState = EditorState.push(editorState, newContentStateWithAddedColon, 'insert-fragment')
        const nextNewEditorState = EditorState.forceSelection(newEditorState, newContentStateWithAddedColon.getSelectionAfter())

        setEditorState(nextNewEditorState)
    }















    function handleChange(editorState) {
        if (mention) {
            const maxNames = 2
            const caret = getCaretLocation(editorState)
            if (caret > mention.offset) {
                const mentionQuery = getText(editorState, mention.offset, caret).replace('@', '').trim()
                // const candidates = users.filter(user => user.name.toLowerCase().startsWith(mentionQuery))

                const wordCount = mentionQuery.split(' ').length

                if ((mentionQuery !== mention.mentionQuery) && wordCount <= maxNames) {
                    const caretPosition = getCaretPopupPosition(editorWrapperRef)
                    setMention(prev => ({ ...prev, mentionQuery, position: caretPosition ? caretPosition : prev.position }))

                    // exit mention mode if it's not mentionsOnly and word count higher than maxNames 
                } else if (!mentionsOnly && (wordCount > maxNames)) {
                    setMention(undefined)
                }

            } else {
                // last change deleted the @ character, so exit mention mode
                setMention(undefined)
            }
        }
        return setEditorState(editorState)
    }


    function handleBeforeInput(ch, editorState) {
        if (mention) {
            // ???
        } else {
            if (enableMention && (ch === '@' || mentionsOnly)) {
                // enter "mention mode"
                setTimeout(() => {
                    setMention({
                        users: [],
                        selectedIndex: 0,
                        offset: getCaretLocation(editorState), // not working 
                        position: getCaretPopupPosition(editorWrapperRef)
                    })
                }, 0)

            }
        }
        return false
    }


    function handleEscape(e) {
        if (mention) {
            setMention(undefined)
            e.preventDefault()
        }
    }


    function acceptSelectedPerson(e) {
        if (mention) {
            if (mention.users && mention.users.length > mention.selectedIndex) {
                let user = mention.users[mention.selectedIndex]
                confirmMention(user)
            } else {
                setMention(undefined)
            }
            e.preventDefault()
            return true
        }
        return false
    }


    function handleTab(e) {
        if (mention) {
            return acceptSelectedPerson(e)

        } else if (!mentionsOnly && enableTextFormat) {
            const maxDepth = 4
            setEditorState(RichUtils.onTab(e, editorState, maxDepth))
            return true
        }
    }



    function handleReturn(e) {
        if (mention) {
            return acceptSelectedPerson(e)

        } else if (enableEnterKeyToSubmit && !mentionsOnly && handleSubmit) {
            handleEditorSubmit()
            return true
        } else {
            return getDefaultKeyBinding(e)
        }
    }


    function handleUpArrow(e) {
        if (mention) {
            setMention(prev => ({
                ...prev,
                selectedIndex: Math.max(0, mention.selectedIndex - 1)
            }))
            e.preventDefault()
        }
    }


    function handleDownArrow(e) {
        if (mention) {
            setMention(prev => ({
                ...prev,
                selectedIndex: Math.min(mention.selectedIndex + 1, mention.users.length - 1)
            }))
            e.preventDefault()
        }
    }




    function handleClick(e) {
        if (!readOnly) editorRef.current.focus()
    }





    function confirmMention(user) {
        const contentState = editorState.getCurrentContent()
        const contentStateWithEntity = contentState.createEntity(
            'MENTION',
            'IMMUTABLE',
            user
        )
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
        const block = getCurrentBlock(editorState)
        const blockKey = block.getKey()
        const mentionText = `${removeMentionPrefix ? '' : '@'}${user.profile.full_name}`
        const contentStateWithReplacedText = Modifier.replaceText(
            contentStateWithEntity,
            new SelectionState({
                anchorKey: blockKey,
                anchorOffset: mention.offset,
                focusKey: blockKey,
                focusOffset: getCaretLocation(editorState),
                isBackward: false,
                hasFocus: true
            }),
            mentionText,
            null,
            entityKey
        )

        // add space after the mention 
        const newContentStateWithSpace = Modifier.insertText(contentStateWithReplacedText, contentStateWithReplacedText.getSelectionAfter(), ' ')


        const newEditorState = EditorState.set(editorState, {
            currentContent: newContentStateWithSpace,
            selection: new SelectionState({
                anchorKey: blockKey,
                anchorOffset: mention.offset + mentionText.length,
                focusKey: blockKey,
                focusOffset: mention.offset + mentionText.length,
                isBackward: false,
                hasFocus: true
            })
        })

        const editorStateToUpdate = EditorState.forceSelection(newEditorState, newContentStateWithSpace.getSelectionAfter())

        setEditorState(editorStateToUpdate)
        setMention(undefined)
    }



    function keyBindingFn(e) {
        // handle Shift+ Enter for soft new line
        if (e.key === 'Enter' && e.shiftKey) {
            const blockType = RichUtils.getCurrentBlockType(editorState)
            // Just to be sure, soft returns are executed only with blockquote, code-block blocks.
            if (blockType !== 'blockquote' && blockType !== 'code-block') {
                return getDefaultKeyBinding(e)
            }
            // Soft Newline
            setEditorState(RichUtils.insertSoftNewline(editorState))
            return 'handled'
        }

        // handle Enter key 
        else if (e.key === 'Enter' && !e.shiftKey) {
            return handleReturn(e)

            // handle Tab key 
        } else if (e.keyCode === 9) {
            return handleTab(e)

            // handle up arrow key
        } else if (e.keyCode === 38) {
            return handleUpArrow(e)

            // handle Up down arrow key
        } else if (e.keyCode === 40) {
            return handleDownArrow(e)

            // handle escape key
        } else if (e.keyCode === 27) {
            return handleEscape(e)

        } else {
            // if (!mentionsOnly) {
            //     // prevent text formatting key function except Shift+Enter 
            //     if (!enableTextFormat && !(e.key === 'Enter' && e.shiftKey)) return
            // }

            // handle default functions
            return getDefaultKeyBinding(e)

        }
    }






    // //WIP 

    // function mediaBlockRenderer(block) {
    //     if (block.getType() === 'atomic') {
    //         return {
    //             component: Media,
    //             editable: false,
    //         };
    //     }

    //     return null
    // }

    // const Audio = (props) => {
    //     return <audio controls src={props.src} />
    // }

    // const Image = (props) => {
    //     return <img src={props.src} />
    // }

    // const Video = (props) => {
    //     return <video controls src={props.src} />
    // }



    // const Media = (props) => {
    //     const entity = props.contentState.getEntity(
    //         props.block.getEntityAt(0)
    //     );
    //     const { src } = entity.getData()
    //     const type = entity.getType()

    //     let media;
    //     if (type === 'audio') {
    //         media = <Audio src={src} />
    //     } else if (type === 'image' || type === 'LINK') {
    //         media = <Image src={src} />
    //     } else if (type === 'video') {
    //         media = <Video src={src} />
    //     }

    //     return media
    // }


    async function handleEditorSubmit() {
        // remove empty lines
        const currentCleanContent = getCurrentCleanContent(editorState)

        const formattedContent = convertToRaw(currentCleanContent)
        const mentions = Object.values(formattedContent.entityMap).filter(entity => entity.type === 'MENTION').map(entity => entity.data.id)
        const contentWithEmojis = currentCleanContent.getPlainText().trim()
        const content = contentWithEmojis.replace(regex.colonsEmojiRegex, '')

        const filteredUploadedFiles = uploadFiles.filter(f => f.image || f.file)


        if (contentWithEmojis || filteredUploadedFiles.length || urlPreviews.length || submitButtonName === 'Share') {

            // stop sending message if it's uploading or there's file error
            for (let i = 0; i < uploadFiles.length; i++) {
                if (uploadFiles[i].uploading || uploadFiles[i].error) {
                    return
                }
            }

            try {
                setIsLoading(true)

                const response = await handleSubmit(content, formattedContent, contentWithEmojis, mentions, filteredUploadedFiles, urlPreviews)
                if (action !== 'UPDATE') {
                    // empty the editor 
                    window.localStorage.setItem(storageName, '')
                    setEditorState(EditorState.push(editorState, ContentState.createFromText(''), 'remove-range'))
                    editorRef.current?.focus()
                }
                setIsLoading(false)
                setLocalFiles([])
                setFileInfos([])
                setUrlPreviews([])
                if (response?.message) {
                    toast.success(response.message)
                }

            } catch (error) {
                setIsLoading(false)
                toast.error(error?.response?.data?.non_field_errors?.toString() || error?.message || 'An unknown error occurred.')
            }

        }
    }

    const styleMap = {
        'CODE': {
            fontFamily: 'Monaco,Menlo,Consolas,Courier New,monospace!important',
            overflowWrap: 'break-word',
            color: '#f2777a',
            fontSize: 12,
            lineHeight: 1.5,
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'normal',
            tabSize: 4,
            padding: '2px 3px',
            margin: '1px 2px',
            border: '1px solid var(--text-color-lightest)',
            borderRadius: 3,
            backgroundColor: 'var(--background-color-lighter)',
        }
    }

    function handleBlockStyleFn(contentBlock) {
        const type = contentBlock.getType()
        if (type === 'blockquote') {
            return styles.blockquote
        } else if (type === 'code-block') {
            return styles.codeBlock
        }
    }



    function shouldHidePlaceholder(editorState) {
        const contentState = editorState.getCurrentContent()
        return (
            contentState.hasText() ||
            contentState
                .getBlockMap()
                .first()
                .getType() !== 'unstyled'
        )
    }


    return (
        <>
            <div className="card-body p-0 position-relative" ref={editorWrapperRef}>
                {!readOnly && enableTextFormat && <ul className="py-1">
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={toggleInlineStyle}
                    />

                    <BlockStyleControls
                        editorState={editorState}
                        onToggle={toggleBlockType}
                    />

                </ul>
                }


                <div onClick={handleClick} className={`${!readOnly ? 'background-color-light p-1' : ''} text-break w-100 rounded-xxl font-xsss`}>
                    {(!readOnly || (readOnly && formattedContent.blocks?.length > 0)) &&
                        <ConditionalWrapper
                            condition={!clampOptions?.disable && readOnly}
                            wrapper={children => <Clamp
                                lines={clampOptions?.lines || 6}
                                maxLines={clampOptions?.maxLines || 500}
                                withTooltip={clampOptions?.withTooltip || false}
                                withToggle={clampOptions?.withToggle || false}
                                showMoreElement={({ toggle }) => (<span onClick={toggle} className='ms-1 pointer text-primary font-xsss'>View more</span>)}
                                showLessElement={({ toggle }) => (<span onClick={toggle} className='ms-1 pointer text-primary font-xsss'>View less</span>)}
                            >{children}</Clamp>}>
                            <div className={`${!readOnly ? 'p-2 overflow-auto' : ''} position-relative`} style={{ maxHeight: !readOnly ? 500 : null }}>
                                <Editor
                                    ref={editorRef}
                                    readOnly={readOnly}
                                    placeholder={readOnly || shouldHidePlaceholder(editorState) ? null : placeHolder}
                                    spellCheck={true}
                                    editorState={editorState}
                                    onChange={handleChange}
                                    stripPastedStyles={stripPastedStyles}
                                    handleBeforeInput={handleBeforeInput}
                                    handleKeyCommand={handleKeyCommand}
                                    keyBindingFn={keyBindingFn}
                                    customStyleMap={styleMap}
                                    blockStyleFn={handleBlockStyleFn}
                                // blockRendererFn={mediaBlockRenderer}
                                />
                            </div>
                        </ConditionalWrapper>}

                    {/* show files */}
                    {uploadFiles.length > 0 && <div className={`${readOnly ? 'd-flex flex-wrap' : 'd-flex flex-wrap'} mt-2`}>
                        {uploadFiles.map(fileInfo => {
                            if (!readOnly) {
                                return <UploadFile fileInfo={fileInfo} editMode={true} key={fileInfo.name} />

                            } else if (!fileInfo.content_type?.includes('video') && readOnly) {
                                return <UploadFile fileInfo={fileInfo} editMode={false} key={fileInfo.name} />
                            }
                            return null
                        })}
                    </div>}

                    {/* videos */}
                    {readOnly && uploadFiles.length > 0 && uploadFiles.map(fileInfo => {
                        if (fileInfo.content_type?.includes('video')) {
                            return <VideoPlayer url={fileInfo.fileSrc} key={fileInfo.name} />
                        }
                        return null
                    })}



                    {/* show url previews */}
                    {/* {urlPreview && <PreviewUrl urlPreview={urlPreview} setUrlPreview={setUrlPreview} editMode={true} />} */}
                    {urlPreviews.length > 0 && <div>
                        {urlPreviews.map((urlPreview, idx) => <PreviewUrl urlPreview={urlPreview} setUrlPreviews={setUrlPreviews} editMode={!readOnly} compactView={urlPreviews.length > 1 ? true : false} key={idx} index={idx} />)}
                    </div>}

                </div>



                {!readOnly && <>
                    <div className="card-body d-flex p-0 px-2 mt-0 mb-3">

                        {/* upload files */}
                        {enableUploadFiles && uploadFilesEndpoint && <>
                            <label htmlFor={inputId} className="d-flex pointer align-items-center font-xssss fw-500 ls-1 text-color-lighter  me-4 mt-3">
                                <FaImage className="font-md text-success me-2" />
                                <span className="d-none-xs">Photos</span>
                            </label>

                            <label htmlFor={inputId} className="d-flex pointer align-items-center font-xssss fw-500 ls-1 text-color-lighter  me-4 mt-3">
                                <FaVideo className="font-md text-red me-2" />
                                <span className="d-none-xs">Videos</span>
                            </label>

                            <label htmlFor={inputId} className="d-flex pointer align-items-center font-xssss fw-500 ls-1 text-color-lighter  me-4 mt-3">
                                <FaPaperclip className="font-md text-primary me-2" />
                                <span className="d-none-xs">Files</span>
                            </label>

                            <input
                                ref={fileInputRef}
                                id={inputId}
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*, video/mp4,video/x-m4v,video/*, audio/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, .zip, .rar, .7zip, .xlsx,.xls, .pptx, .torrent"
                                multiple
                                onChange={e => setLocalFiles(prev => prev.concat(Array.from(e.target.files)))}
                            />
                        </>}


                        {/* emojis */}
                        {enableEmoji && <EmojiPickerComponent user={user} handleAddingEmoji={handleAddingEmoji} insidePopup={insidePopup} />}


                        {/* action button */}
                        {submitButtonName && <button onClick={handleEditorSubmit} className="d-flex align-items-center ms-auto btn btn-primary p-2 px-3 d-inline-block font-xssss fw-500 text-white mt-3">
                            {isLoading ?
                                <span className="spinner-border spinner-border-sm mx-2" role="status" aria-hidden="true"></span> :
                                <>{submitButtonName}</>
                            }
                        </button>}

                    </div>
                </>}


                {mention?.mentionQuery ?
                    <UserSuggestion
                        {...mention}
                        onClick={confirmMention}
                        setMention={setMention}
                        elementRef={UserSuggestionRef} />
                    : null
                }

            </div>
        </>
    )

}
