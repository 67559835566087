import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom"
import { useSelector } from 'react-redux'
import axios from 'axios'
import { toast } from 'react-toastify'
import NotFound from '../404/NotFoundView'
import Post from './post/Post'

export default function PostView() {

    const [isLoading, setIsLoading] = useState(false)
    const [post, setPost] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const isAuthenticated = useSelector((state) => state.userReducer.isAuthenticated)
    const { postId } = useParams()


    // Generate Open Graph metadata
    const generateMetaTags = (post) => {
        const title = `${post.content ? (post.content?.length > 50 ? post.content.slice(0, 50) + '...' : post.content) : post.user.profile.full_name}`
        const description = post.content ? post.content.slice(0, 250) : ''
        const imageUrl = post.user.profile.thumbnail  // TODO: Use the post image instead

        return {
            title,
            description,
            imageUrl,
            url: window.location.href  // Current page URL
        }
    }

    useEffect(() => {
        const source = axios.CancelToken.source()

        async function fetchData() {
            setIsLoading(true)

            try {
                // pass route params
                const response = await axios.get(`/newsfeed/post/${postId}/`, {
                    cancelToken: source.token
                })

                if (response?.status === 200) {
                    setPost(response.data)
                    setIsLoading(false)

                    // Dynamically set the meta tags for Open Graph
                    const metaTags = generateMetaTags(response.data)
                    document.title = metaTags.title
                    
                    // Open Graph Meta Tags
                    document.querySelector('meta[property="og:title"]').setAttribute('content', metaTags.title)
                    document.querySelector('meta[property="og:description"]').setAttribute('content', metaTags.description)
                    document.querySelector('meta[property="og:image"]').setAttribute('content', metaTags.imageUrl)
                    document.querySelector('meta[property="og:url"]').setAttribute('content', metaTags.url)
                }


            } catch (err) {
                if (axios.isCancel(err)) return
                if (err.response?.status && err.response?.status === 404) {
                    setNotFound(true)
                }
                toast.error(err?.response?.data?.non_field_errors?.toString() || err?.message || 'An unknown error occurred.')
                setIsLoading(false)
            }
        }
        if (postId.length === 16) {
            fetchData()
        } else {
            setNotFound(true)
        }


        return () => {
            return source.cancel()
        }

    }, [postId])

    return notFound ?
        <NotFound /> :

        <div className="main-content right-chat-active">
            <div className="middle-sidebar-bottom">
                <div className="middle-sidebar-left">
                    <div className="text-color">
                        {post && <Post loading={isLoading} post={post} disableClamp={true} renderComments={true} isAuthenticated={isAuthenticated}/>}
                    </div>
                </div>
            </div>
        </div>
}
